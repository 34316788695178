








import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ProjectsTableComponent from '../components/ProjectsTableComponent.vue';
import ErrorComponent from '../components/ErrorComponent.vue';
import { handleError } from '../lib/shared';
import * as features from '../lib/feature-constants';
import * as _ from 'lodash';

@Component({
    components: {
      ErrorComponent,
      ProjectsTableComponent,
    },
  })
export default class ClientProjects extends BaseComponent {

  public arrErrors: Error[] = [];
  protected baseRoute: string = '/clients';
  protected feature: string = features.clientMenu;
  private clientId: string = '';

  protected mounted() {
    this.mount();
  }

  private mount() {
    this.clientId = this.$route.params.id;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }
}
